<template>
    <div class="login-container" v-if="isBrowser">
        <nuxt-link :to="localePath('/')">
            <div class="logo-img"></div>
        </nuxt-link>
        <h2>{{ $t('login') }}</h2>

        <div class="login-inner-container">
            <v-flex>
                <span class="login-label">Email *</span>
                <v-text-field
                    v-model="loginEmail"
                    class="email-input"
                    :rules="[rules.required, rules.emailValid]"
                ></v-text-field>
            </v-flex>
            <v-flex>
                <span class="login-label">{{ $t('password') }} *</span>
                <v-text-field
                    v-model="loginPassword"
                    :append-icon="secureLoginPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="secureLoginPassword ? 'password' : 'text'"
                    name="input-10-1"
                    hint="At least 8 characters"
                    class="pass-input"
                    counter
                    @click:append="secureLoginPassword = !secureLoginPassword"
                    v-on:keyup.enter="login"
                >
                </v-text-field>
            </v-flex>
            <v-flex>
                <div class="errors-container" v-if="errorMsg !== ''">
                    <p>{{ errorMsg }}</p>
                </div>
            </v-flex>
            <v-flex>
                <v-btn class="submit login-submit" v-on:click="login">{{ $t('login') }}</v-btn>
            </v-flex>
            <v-flex>
                <div class="social-title">
                    <span>{{ $t('register-or') }}</span>
                    <div class="social-border"></div>
                </div>
                <div class="social-btns-wrapper">
                    <v-facebook-login app-id="4547708611999159" @login="loginFb" @sdk-init="handleSdkInit">
                        <button slot="login">Sign in with Facebook</button>
                    </v-facebook-login>
<!--                    <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="loginGgl" :onCurrentUser="loggedGgl"></GoogleLogin>-->
                    <vue-apple-signin v-if="appleLoaded" color="black" :border="true" type="sign in"></vue-apple-signin>
                </div>
            </v-flex>
        </div>

        <div class="footer-links">
            <div class="footer-link">
                <div class="forgot-pass">
                    <nuxt-link :to="localePath('/forgot-password')">{{ $t('register-forgot-pass') }}</nuxt-link>
                    <div class="link-border"></div>
                </div>
            </div>
            <div class="footer-link text-center">
                <div class="terms">
                    <nuxt-link :to="localePath('/terms-and-conditions')">{{ $t('footer22') }}</nuxt-link>
                    <div class="link-border"></div>
                </div>
            </div>
            <div class="footer-link text-right">
                <div class="create-account">
                    <nuxt-link :to="localePath('/register')" class="ga4-reg-btn">{{ $t('register-create') }}</nuxt-link>
                    <div class="link-border"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {required, email, minLength} from 'vuelidate/lib/validators';
import {AAuth, AUser} from '~/store/actions';
import GoogleLogin from 'vue-google-login';
import {MAuth} from "~/store/mutations";

export default {
    name: 'Login',
    head: {
        title: 'Login | Fine Wine Trading App',
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: 'Buy and sell fine wines from your smartphone today! Vindome is the fast, secure and easy way to become a wine trader – right from your smartphone.'
            }
        ],
        script: [
            {
                src: "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
            }
        ]
    },
    props: {
        navigation: {
            type: Object
        }
    },
    components: {
        VFacebookLogin: () => process.client ? import('vue-facebook-login-component') : null,
        GoogleLogin
    },
    validations: {
        loginEmail: {
            required,
            email
        },
        loginPassword: {
            required,
            minLength: minLength(8)
        },
        regEmail: {
            required,
            email
        },
        regPassword: {
            required,
            minLength: minLength(8)
        }
    },
    data() {
        return {
            isBrowser: false,
            appleLoaded: false,
            params: {
                client_id: "374038783935-pibb3r2f1vnthclrgb4cqr5tv59ckl2m.apps.googleusercontent.com"
            },
            renderParams: {
                width: 184,
                height: 40,
                longtitle: true
            },
            loginEmail: '',
            loginPassword: '',
            loginPath: '',
            regEmail: '',
            regPassword: '',
            isLoading: false,
            checkedTerms: false,
            checkedNewsletter: false,
            secureLoginPassword: true,
            secureRegPassword: true,
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            rules: {
                required: value => !!value || 'Required.',
                min: value => value.length >= 8 || 'Min 8 characters',
                emailValid: value => this.isEmailValid(value) || 'Invalid email'
            },
            errorMsg: '',
            missingResponseModalFlag: false,
            FB: {},
            fbScope: {},
            userBrowser: '',
            userDevice: ''
        };
    },
    methods: {
        handleSdkInit({FB, scope}) {
            this.FB = FB;
            this.fbScope = scope
        },
        loginFb(response) {
            if (typeof response !== 'undefined' && response.status === "connected") {
                let _loginCtrl = this;

                this.$store.dispatch(AAuth.LOGIN_EXTERNAL_USER, {accessToken: response.authResponse.accessToken}).then((res) => {
                    let token = res.access_token;
                    this.$nuxt.$emit('logged', 'User logged');
                    localStorage.setItem('access_token', token);
                    _loginCtrl.$store.commit(MAuth.SET_ACCESS_TOKEN, token);

                    if (_loginCtrl.loginPath && _loginCtrl.loginPath !== '') {
                        localStorage.removeItem('login_path');
                        _loginCtrl.$router.push(this.localePath({path: _loginCtrl.loginPath}));
                    } else {
                        _loginCtrl.$router.push(this.localePath({path: 'my-account'}));
                    }
                });
            } else if (typeof response === 'undefined') {
                let _loginCtrl = this;
                let _token = this.FB.getAccessToken();

                this.$store.dispatch(AAuth.LOGIN_EXTERNAL_USER, {accessToken: _token}).then((res) => {
                    let token = res.access_token;
                    this.$nuxt.$emit('logged', 'User logged');
                    localStorage.setItem('access_token', token);
                    _loginCtrl.$store.commit(MAuth.SET_ACCESS_TOKEN, token);

                    if (_loginCtrl.loginPath && _loginCtrl.loginPath !== '') {
                        localStorage.removeItem('login_path');
                        _loginCtrl.$router.push(this.localePath({path: _loginCtrl.loginPath}));
                    } else {
                        _loginCtrl.$router.push(this.localePath({path: 'my-account'}));
                    }
                });
            }
        },
        loginGgl(response) {
            let _loginCtrl = this;

            this.$store.dispatch(AAuth.LOGIN_GOOGLE_USER, {id_token: response.wc.id_token}).then((res) => {
                let token = res.access_token;
                this.$nuxt.$emit('logged', 'User logged');
                localStorage.setItem('access_token', token);
                _loginCtrl.$store.commit(MAuth.SET_ACCESS_TOKEN, token);

                if (_loginCtrl.loginPath && _loginCtrl.loginPath !== '') {
                    localStorage.removeItem('login_path');
                    _loginCtrl.$router.push(this.localePath({path: _loginCtrl.loginPath}));
                } else {
                    _loginCtrl.$router.push(this.localePath({path: 'my-account'}));
                }
            });
        },
        loggedGgl(response) {
            let _loginCtrl = this;

            this.$store.dispatch(AAuth.LOGIN_GOOGLE_USER, {id_token: response.wc.id_token}).then((res) => {
                let token = res.access_token;
                this.$nuxt.$emit('logged', 'User logged');
                localStorage.setItem('access_token', token);
                _loginCtrl.$store.commit(MAuth.SET_ACCESS_TOKEN, token);

                if (_loginCtrl.loginPath && _loginCtrl.loginPath !== '') {
                    localStorage.removeItem('login_path');
                    _loginCtrl.$router.push(this.localePath({path: _loginCtrl.loginPath}));
                } else {
                    _loginCtrl.$router.push(this.localePath({path: 'my-account'}));
                }
            });
        },
        isEmailValid: function (email) {
            return this.reg.test(email)
        },
        login() {
            if (this.loginEmail && this.loginPassword && !this.$v.loginEmail.$invalid && !this.$v.loginPassword.$invalid) {
                this.isLoading = true;

                this.$store.dispatch(AAuth.GET_CONFIRMED, this.loginEmail).then((res) => {
                    if (res) {
                        localStorage.removeItem('user_email');
                        this.$store.dispatch(AAuth.GET_TOKEN, {email: this.loginEmail, pass: this.loginPassword, browser: this.userBrowser, device: this.userDevice}).then(() => {
                            this.isLoading = false;
                            this.errorMsg = '';
                            this.$store.dispatch(AUser.GET_USER_DATA);
                            this.$nuxt.$emit('logged', 'User logged');

                            if (this.loginPath && this.loginPath !== '') {
                                localStorage.removeItem('login_path');
                                this.$router.push(this.localePath({path: this.loginPath}));
                            } else {
                                this.$router.push(this.localePath({path: 'my-account'}));
                            }
                        }).catch(err => {
                            this.isLoading = false;
                            this.errorMsg = 'Wrong email or password';
                        })
                    } else {
                        localStorage.setItem('user_email', this.loginEmail);
                        this.$router.push(this.localePath({path: 'login-pending'}));
                    }
                }).catch(() => {
                    this.isLoading = false;
                    this.errorMsg = 'Wrong email or password';
                })
            } else if (this.$v.loginEmail.$invalid) {
                this.errorMsg = 'The email you entered is not valid. Try again.';
            } else if (this.$v.loginPassword.$invalid) {
                this.errorMsg = 'The password you entered is not valid. Try again.';
            }
        },
        getLoginPath() {
            this.loginPath = localStorage.getItem('login_path');
        },
        loginApple(response) {
            console.log('=============');
            console.log(response);
            console.log('=============');
        },
        fnBrowserDetect(){
            let userAgent = navigator.userAgent;
            let browserName;

            if (userAgent.match(/chrome|chromium|crios/i)){
                browserName = "Chrome";
            } else if(userAgent.match(/firefox|fxios/i)){
                browserName = "Firefox";
            }  else if(userAgent.match(/safari/i)){
                browserName = "Safari";
            } else if(userAgent.match(/opr\//i)){
                browserName = "Opera";
            } else if(userAgent.match(/edg/i)){
                browserName = "Edge";
            } else{
                browserName="No browser detection";
            }


            return browserName
        },
        getMobileOperatingSystem() {
            let userAgent = navigator.userAgent || navigator.vendor || window.opera;

            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        },
        detectDeviceType() {
            let device;

            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                device = this.getMobileOperatingSystem();
            } else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
                device = 'Tablet'
            } else {
                if (navigator.platform.indexOf('Win') !== -1) {
                    device = 'Windows'
                } else if (navigator.platform.indexOf('Mac') !== -1) {
                    device = 'Mac OS'
                } else if (navigator.platform.indexOf('Linux') !== -1) {
                    device = 'Linux'
                } else {
                    device = 'Desktop'
                }
            }

            return device
        }
    },
    mounted() {
        this.isBrowser = process.browser;
        this.getLoginPath();
        this.userBrowser = this.fnBrowserDetect();
        this.userDevice = this.detectDeviceType();

        setTimeout(() => {
            this.appleLoaded = true
        }, 1200)

        this.$nuxt.$on('loggedout', () => {
            if (this.fbScope) {
                this.fbScope.logout()
            }
        });
    }
}
</script>

<style scoped>
.social-btns-wrapper {
    text-align: center;
    align-content: center;
    justify-content: center;
}

.social-title {
    text-align: center;
    color: #707070;
}

.social-title span {
    background: #f5f6f5;
    position: relative;
    z-index: 9;
    padding: 0 10px;
}

.social-border {
    height: 1px;
    width: 100%;
    background: #707070;
    position: relative;
    top: -11px;
}

.login-container {
    text-align: center;
    padding: 0 calc(3 * var(--gutter)) calc(4 * var(--gutter));
}

.logo-img {
    background: url('../assets/v_dark.svg') no-repeat center 50px;
    background-size: contain;
    width: 300px;
    height: 180px;
    margin: 0 auto;
}

h2 {
    color: #707070;
    margin-bottom: calc(2 * var(--gutter));
}

.login-inner-container {
    max-width: 700px;
    background: #F5F6F5;
    border-radius: 10px;
    padding: 30px 50px 50px;
    margin: 0 auto;
    position: relative;
}

.login-label {
    position: absolute;
    font-size: var(--fs-base);
    color: #303030;
    font-weight: 300;
}

.login-container .submit {
    margin-top: 0;
    background: #E1C376 !important;
    color: #FFF !important;
    width: 200px;
    height: 40px !important;
    font-size: 18px;
    border-radius: 5px;
    text-transform: none;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    font-weight: lighter;
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
}

.footer-links {
    max-width: 700px;
    margin: 20px auto 0;
    display: flex;
}

.footer-link {
    display: inline-block;
    width: 33.33%;
}

.footer-links .terms {
    width: max-content;
    margin: 0 auto;
}

.footer-links .forgot-pass,
.footer-links .create-account {
    width: max-content;
}

.footer-links .create-account {
    float: right;
}

.link-border {
    height: 1px;
    width: 100%;
    background: #707070;
}

.footer-links > div:hover .link-border {
    background: var(--brand);
}

@media (max-width: 767px) {
    h2 {
        font-weight: 300;
        font-size: 28px;
        line-height: 30px;
    }

    .login-container .submit {
        width: 100%;
    }

    .login-container {
        padding: 0 15px 30px;
    }

    .logo-img {
        background: url('../assets/v_dark.svg') no-repeat center;
        background-size: contain;
        width: 170px;
        height: 80px;
    }

    .login-inner-container {
        padding: 30px 15px;
    }

    .footer-links {
        display: block;
    }

    .footer-link {
        display: block;
        width: 100%;
        text-align: center !important;
    }

    .footer-links .forgot-pass,
    .footer-links .create-account {
        width: 100%;
    }

    .link-border {
        height: 0;
    }

    .footer-links .create-account {
        float: none;
        margin-top: 5px;
    }

    .footer-links .terms {
        margin-top: 5px;
    }
}
</style>

<style>
.v-facebook-login {
    margin: 15px auto;
    width: 240px;
}

#google-signin-btn-0 {
    width: 240px;
    margin: 0 auto;
}

#google-signin-btn-0 .abcRioButton.abcRioButtonLightBlue {
    width: 240px !important;
}

#appleid-signin {
    margin: 15px auto 0;
    width: 240px;
}

.loginpage .header-login-btn,
.loginpage .header-register-btn,
.loginpage .header-container {
    display: none;
}

.login-container .flex {
    margin-top: 20px;
    text-align: left;
}

.login-container .v-input input {
    height: 50px;
    max-height: unset;
    margin-top: 20px;
    padding-left: 15px;
}

.login-container .v-text-field .v-input__append-inner {
    margin-top: 20px;
    background: #fff;
    height: 50px;
}

.login-container .v-text-field .v-input__append-inner .v-input__icon {
    margin-top: 12px;
}

.login-container .v-text-field .v-input__append-inner .primary--text {
    color: #000 !important;
    caret-color: #000 !important;
}

.login-container .theme--light.v-input:not(.v-input--is-disabled) input {
    border: 0;
}

.login-container .theme--light.v-text-field:not(.v-input--has-state) > .v-input__control > .v-input__slot:hover:before {
    border-color: var(--brand);
}

.login-container .theme--light.v-text-field > .v-input__control > .v-input__slot:after {
    border-color: rgba(0, 0, 0, .87);
    border-width: 1px;
}

.login-container .theme--light.v-text-field.error--text > .v-input__control > .v-input__slot:after {
    border-color: #ff5252;
}

.login-container .footer-links a {
    text-decoration: none;
    color: #707070;
    font-size: 19px;
    font-weight: 300;
}

.login-container .footer-links a:hover {
    color: var(--brand);
}

.theme--light.v-application.loginpage {
    background: #ffffff;
}

#appleid-signin {
    height: 40px;
}
</style>
